import React, { useEffect } from "react";
import { routes } from "../routes";
import { Loading, NavigationPane } from "../../components";
import { Route, useHistory } from "react-router-dom";
import { Header } from "../../components/header/header";
import { useAuth } from "../../context/auth/auth.provider";
import { useNavOpen } from "../../context/navigation/nav.provider";
import { useApp } from "../../context/app/app.provider";
import { Container, Content, HorizontalContainer } from "./privateRoute.styles";
import { useQueryParams } from "../../context/hooks";
import { useProject } from "../../context/project/project.provider";
import { PROJECT } from "../../static/constants/constants";
import { useInit } from "../../views/init/hooks/useInit";

export const PrivateRoute = ({ children, path, location, ...props }) => {
  const outsideProject = [
    routes.PROJECTS.path,
    routes.PROFILE.path,
    routes.DOWNLOADS.path,
    routes.ORGANISATION_SETTINGS.path,
    routes.INIT.path,
  ].includes(path);

  const { isAuthenticated } = useAuth(true);
  const { navIsOpen } = useNavOpen();
  const { isNotDesktop } = useApp();
  const { getParam } = useQueryParams();
  const { project, setProject, projectId, projects } = useProject();
  const { getAllSetup, ready, isOrgLoaded, checkAuthOptions } = useInit();
  const history = useHistory();

  const projectParam = getParam(PROJECT);

  useEffect(() => {
    if (location?.pathname === routes.PROJECTS.path) return;

    if (project && !projectParam) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set(PROJECT, project.id);
      window.location.search = urlParams;
    }

    const isAvailableProject = projects?.some(
      p => p.id?.toString() === projectParam?.toString()
    );
    if (!outsideProject && projects && projectParam && !isAvailableProject)
      history.push(routes.PROJECTS.path);

    if (
      projects &&
      projectParam &&
      projectId !== projectParam &&
      isAvailableProject
    )
      setProject(projectParam);
  }, [window.location, location, projects, projectId, outsideProject]);

  useEffect(() => {
    if (isAuthenticated) getAllSetup();
  }, [isAuthenticated]);

  useEffect(() => {
    checkAuthOptions();
  }, []);

  if (!ready && !isOrgLoaded) return <Loading />;
  if (!outsideProject && projectParam && projectId !== projectParam)
    return <Loading />;

  if (!isAuthenticated)
    window.location.href = `${window.location.protocol}//${window.location.host}${routes.SIGNIN.path}`;

  return (
    <Container>
      <Header outsideProject={outsideProject} />
      <HorizontalContainer>
        {outsideProject ? null : <NavigationPane />}
        <Content navIsOpen={navIsOpen} isNotDesktop={isNotDesktop} role="main">
          <Route exact path={path} render={() => children} {...props} />
        </Content>
      </HorizontalContainer>
    </Container>
  );
};
