import React from "react";
import { useTranslation } from "react-i18next";
import { Container, GhostContainer, Row } from "./projectDropdown.styles";

export const ProjectDropdown = ({ open }) => {
  const { t } = useTranslation();

  return open ? (
    <Container>
      <Row as="a" href="/projects">
        {t("projectView.switchProject")}
      </Row>
    </Container>
  ) : (
    <GhostContainer />
  );
};
