import { Button, Input, CenteredContainer, Message } from "../../components";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormContainer } from "./setPassword.styles";
import { useSetPassword } from "./hooks/useSetPassword";
import { Checkbox } from "../../components/checkbox/checkbox";
import {
  StyledForm,
  CompanyLogo,
  ProductLogo,
  InputContainer,
  StyledContainer,
  SignInContainer,
  CheckboxContainer,
  RequirementsContainer,
} from "../signIn/signIn.styles";
import { useAuth } from "../../context/auth/auth.provider";
import { routes } from "../../router/routes";

export const SetPassword = ({ resetPassword }) => {
  const { t } = useTranslation();
  const {
    error,
    formRef,
    formError,
    isLoading,
    emptyField,
    setPassword,
    buttonHandler,
    setPasswordSuccess,
    setConfirmPassword,
    resetFields,
  } = useSetPassword();
  const { setUnAuthenticated } = useAuth();

  useEffect(() => {
    setUnAuthenticated();
  }, []);

  if (setPasswordSuccess)
    window.location.href = `${window.location.protocol}//${window.location.host}${routes.SIGNIN.path}`;

  return (
    <SignInContainer>
      <StyledContainer>
        <ProductLogo />
        {error ? (
          <Message data-test="error-message" error>
            {t(`errors.setPassword.${error.code || error.text}`)}
          </Message>
        ) : null}
        <StyledForm ref={formRef} onFocus={() => resetFields()}>
          <FormContainer>
            {resetPassword ? (
              <p>{t("signin.resetPasswordSupport")}</p>
            ) : (
              <p>{t("signin.createPasswordSupport")}</p>
            )}
            <InputContainer>
              <Input
                type="password"
                name="password"
                data-test="password-input"
                label={t("signin.createPassword")}
                error={emptyField.includes(1)}
                onChange={e => setPassword(e.target.value)}
              />
              {emptyField.includes(1) ? <p>{t("signin.required")}</p> : null}
            </InputContainer>
            <InputContainer>
              <Input
                type="password"
                name="password-confirm"
                data-test="password-confirm-input"
                label={t("signin.confirmPassword")}
                error={emptyField.includes(2)}
                onChange={e => setConfirmPassword(e.target.value)}
              />
              {emptyField.includes(2) ? <p>{t("signin.required")}</p> : null}
            </InputContainer>
            <RequirementsContainer>
              <CheckboxContainer>
                <Checkbox disabled={true} checked={!formError.length} />
                <p>{t("setPassword.passwordLengthMessage")}</p>
              </CheckboxContainer>
              <CheckboxContainer>
                <Checkbox disabled={true} checked={!formError.inequality} />
                <p>{t("setPassword.passwordsMatch")}</p>
              </CheckboxContainer>
            </RequirementsContainer>
            <CenteredContainer fullWidth>
              <Button
                primary
                captcha
                loading={isLoading}
                loader={true}
                data-test="confirm-button"
                onClick={buttonHandler}
                disabled={formError.error}
              >
                {t("btnConfirm")}
              </Button>
            </CenteredContainer>
          </FormContainer>
        </StyledForm>
        <CompanyLogo />
      </StyledContainer>
    </SignInContainer>
  );
};
