import styled from "styled-components";

export const Input = ({
  label,
  margin,
  width,
  onChange,
  type,
  placeholder,
  defaultValue,
  inputRef,
  ...rest
}) => (
  <StyledInputContainer margin={margin} width={width}>
    {label ? <StyledLabel>{label}</StyledLabel> : null}
    <StyledInput
      placeholder={placeholder || label}
      onChange={onChange}
      type={type}
      defaultValue={defaultValue}
      ref={inputRef}
      {...rest}
    />
  </StyledInputContainer>
);

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ margin }) => margin};
  width: ${({ width }) => (width ? width : "100%;")};
`;

export const StyledInput = styled.input`
  height: ${({ theme, height }) => height || theme.sizing.inputHeight};
  border-radius: ${({ theme }) => theme.borders.radius};
  border: 1px solid;
  padding: 0 ${({ theme }) => theme.spacing.padding};
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
  background-color: ${({ theme }) => theme.colours.background};
  color: ${({ theme }) => theme.colours.mainText};

  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colours.error : theme.colours.borderColour};

  &:focus {
    border: 1px solid
      ${({ theme, error }) =>
        error ? theme.colours.error : theme.colours.haloAccent};
    outline: none;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colours.secondaryText};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colours.secondaryText};
  }
`;

export const StyledLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
  text-align: ${props => (props.align ? props.align : "left")};
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
`;
