import { FONT_FAMILY, FONT_WEIGHT, BASELINE, PALETTE } from "./constants";

const palette = PALETTE;

export const theme = {
  typography: {
    fontFamily: FONT_FAMILY,
  },
};

theme.fontWeight = {
  light: FONT_WEIGHT.LIGHT,
  regular: FONT_WEIGHT.REGULAR,
  medium: FONT_WEIGHT.MEDIUM,
  mediumBold: FONT_WEIGHT.MEDIUM_BOLD,
  bold: FONT_WEIGHT.BOLD,
};

theme.fontSize = {
  smallest: "10px",
  smaller: "12px",
  smallerMedium: "13px",
  medium: "14px",
  mediumLarge: "16px",
  large: "18px",
  largeMiddler: "20px",
  largeMiddle: "22px",
  larger: "24px",
  largest: "32px",
  graphValue: "40px",
  largester: "48px",
  huge: "100px",
};

theme.collapseButton = {
  marginOpen: "-3px",
  marginClosed: "-1px",
  height: "40px",
  width: "32px",
  marginRight: "-20px",
  spacingTop: "40px",
};

theme.transitionTime = {
  fast: "0.1s",
};

theme.letterSpacing = "0.7px";

theme.borders = {
  radiusSM: BASELINE.SP0_5,
  radius: BASELINE.SP01,
  radiusMD: BASELINE.SP02,
  radiusLG: BASELINE.SP04,
  cardRadius: BASELINE.SP01,
  noBorder: "none",
  header: "none",
  dropdownBorder: "none",
  headerBorder: "none",
  default: "1px solid black",
};

theme.lineHeight = {
  medium: "36px",
  large: "40px",
};

theme.spacing = {
  paddingSmallest: BASELINE.SP0_125,
  paddingXXSM: BASELINE.SP0_25,
  paddingXSM: BASELINE.SP0_5,
  paddingSM: BASELINE.SP01,
  padding: BASELINE.SP02,
  paddingMD: BASELINE.SP03,
  paddingLG: BASELINE.SP04,
  paddingLGR: BASELINE.SP06,
  paddingXL: BASELINE.SP08,
  paddingXXL: BASELINE.SP09,

  dropdownOffsetRight: "32px",
  dropdownOffsetTop: "64px",
  labelTop: "-60%",
  switchProjectDropdownOffset: "220px",
  graph: "20px",
  projectPicker: "2px",
};

theme.sizing = {
  full: "100%",
  btnHeight: "40px",
  nodeBtnWidth: "136px",
  inputHeight: "32px",
  inputHeightLarge: "38px",
  btnWidth: "160px",
  labelWidth: "48px",
  btnWidthLG: "260px",
  nodeCardHeight: "500px",
  nodeCardContainerHeight: "532px",
  nodeCardWidth: "320px",
  nodeCardLabelWidth: "260px",
  projectCardHeight: "400px",
  projectCardWidth: "320px",
  navItemHeight: "56px",
  iconSmall: "18px",
  iconSize: "26px",
  nodeCardTopContainerHeight: "32px",
  nodeCardTopContainerWidth: "200px",
  scrollBarWidth: "8px",
  runTrendsHeight: "500px",
  runTrendsHeightMobile: "300px",
  tabLegth: "120px",
  projectDetailsHeight: "120px",
  projectDropdownMargin: "40px",
  screenshotSectionHeight: "120px",
  arrowOffset: "-12px",
  nodeFooterHeight: "24px",
  freezeBtnWidth: "80px",
  releaseCardHeight: "280px",
  checkMarkSize: "30px",
  circleSize: "64px",
  homeSectionHeight: "400px",
  maxViolationStickerWidth: "136px",
  runDetailMaxWidth: "400px",
  runDetailMinWidth: "120px",
  selectedRunDetailMinWidth: "400px",
  runDetailMinHeight: "56px",
  runDetailEditHeight: "200px",
  imgHeight: "240px",
  entryWidth: "120px",
  sectionHeight: "960px",
  carouselHeight: "80px",
  textAreaHeight: "80px",
  textAreaMinHeight: "160px",
  downloadOsCode: "280px",
  downloadProductCard: "360px",
  minimalRunCardHeight: "280px",
  minimalRunCardLabelHeight: "56px",
  minimalRunCardLabelWidth: "288px",
  minimalGraphHeight: "12px",
  minimalStickerContainer: "88px",
  gettingStartedHeight: "816px",

  labelToReleaseContainerHeight: "480px",
  accessibilityViewSectionheight: "65vh",

  paginationBtnHeight: "2em",
  paginationBtnWidth: "1em",
  scrollOffset: "100px",

  tickThickness: "3px",
  ringDiameter: "3px",
  ring: "12px",
  svgHeightXSM: "14px",
  svgHeightSM: "16px",
  svgHeightMD: "20px",
  svgHeightMDLG: "22px",
  svgHeight: "24px",
  svgHeightLG: "48px",
  svgWidth169: "86px",
  latestRunWidgetHeight: "520px",
  latestRunWidgetWidth: "800px",
  stickerHeight: "40px",
  stickerWidth: "130px",
  teLogoWidth: "160px",
  selectMaxWidth: "560px",
  shapeSize: "6px",
  shapeSizeLG: "12px",
  deleteBtnWdith: "20px",
  runPropertiesHeight: "42px",
  entryDetailsHeight: "72px",
  releaseCardGraphHeight: "72px",
  statusSticker: "72px",
  reportInputWidth: "320px",

  attachmentModalHeight: "50vh",
  attachmentModalWidth: "50vw",

  headerHeight: "70px",
  headerLogoHeight: "56px",
  profileIconHeight: "40px",
  dropdownRowHeight: "48px",
  dropdownMenuWidth: "240px",
  featuresListHeight: "500px",
  teLogoHeight: "24px",
  haloLogo: "56px",
  haloLogoMobile: "32px",
  jiraLogoWidth: "400px",
  selectWidth: "400px",
  statusWidth: "140px",
  statusHeight: "56px",
  assessmentMinWidth: "320px",
  assessmentMaxWidth: "800px",

  graphContainerXSM: "100px",
  graphContainerSM: "140px",
  graphContainerM: "150px",
  graphContainerML: "180px",
  graphContainerL: "240px",
  graphLG: "200px",
  graphLabelLG: "120px",

  summaryWidth: "100px",
  sectionHeaderHeight: "40px",
  scenarioNameHeight: "40px",
  checksStickerHeight: "30px",
  checksStickerWidth: "90px",
  checksStickerWidthSM: "65px",
  modalWidthEmail: "728px",

  modalWidthSmall: "500px",
  modalWidth: "650px",
  modalWidthLarge: "90%",
  formErrorHeight: "20px",
  reportHeaderHeight: "60px",

  approvalMessageWidth: "300px",
  approvalMessageHeight: "100px",
  loadingContainerHeight: "100px",

  textAreaMin: "96px",
  textAreaMax: "240px",
  exploratoryStatusStickerWidth: "124px",
  exploratoryStatusStickerWidthSmall: "96px",

  checkbox: {
    top: "2px",
    left: "7px",
    beforeWidth: "8px",
    beforeHeight: "14px",
    size: "1.5em",
  },
};

theme.colours = {
  background: palette.neutrals.normal,
  foreground: palette.white,
  haloAccent: palette.purples.normal,
  haloAccentLight: palette.purples.light,
  error: palette.reds.error,
  success: palette.greens.success,
  pass: palette.greens.pass,
  freezeButtonBackground: palette.blues.freeze_light,
  freezeButtonText: palette.blues.freeze_dark,
  fadedMainText: palette.neutrals.faded_grey,

  openButtonBackground: palette.purples.openBackground,
  closedButtonBackground: palette.greens.closedBackground,
  closedButtonForeground: palette.greens.closedForeground,

  pending: palette.neutrals.lightGrey,
  white: palette.white,
  black: palette.black,
  pale_green: palette.greens[50],
  pale_red: palette.reds[50],
  scrollbar: palette.white,
  light_grey: palette.neutrals.normal,

  severity: {
    highest: palette.reds.critical,
    high: palette.reds.high,
    medium: palette.yellows.moderate,
    low: palette.yellows.low,
    info: palette.blues.smoke,
    new: palette.blues.new_end,
  },
  status: {
    passed: palette.greens.success,
    failed: palette.reds.high,
    error: palette.reds.critical,
    skipped: palette.blues.smoke,
    new: palette.blues.new_end,
  },
  jira: {
    done: {
      text: "#006644",
      background: "#E3FCEF",
    },
    other: {
      text: "#0052CC",
      background: "#E9F2FF",
    },
    to_do: {
      text: "#42526E",
      background: "#F3F4F5",
    },
  },
};

theme.cursors = {
  btnLoading: "wait",
  btn: "pointer",
  link: "pointer",
};

theme.gradients = {
  passing_start: palette.greens.success_start,
  passing_end: palette.greens.succes_end,
  new_start: palette.blues.new_start,
  new_end: palette.blues.new_end,
  error_start: palette.reds.error_start,
  error_end: palette.reds.error_end,
  critical_start: palette.reds.critical_start,
  critical_end: palette.reds.critical_end,
  moderate_start: palette.oranges.moderate_start,
  moderate_end: palette.oranges.moderate_end,
  low_start: palette.yellows.low_start,
  low_end: palette.yellows.low_end,
  info_start: palette.blues.info_start,
  info_end: palette.blues.info_end,
  skipped_start: palette.neutrals.skipped_start,
  skipped_end: palette.neutrals.skipped_end,
  total_scenarios_start: palette.neutrals.skipped_start,
  total_scenarios_end: palette.neutrals.skipped_end,
  passing_check_start: palette.blues.check_start,
  passing_check_end: palette.blues.check_end,
  failed_check_start: palette.reds.error_start,
  failed_check_end: palette.reds.error_end,
  purple_start: palette.purples.light,
  purple_end: palette.purples.normal,
};

export const light = {
  ...theme,
  colours: {
    ...theme.colours,
    background: palette.neutrals.normal,
    foreground: palette.white,
    altForeground: palette.white,
    bulletColour: palette.purples.normal,
    bar: palette.white,
    pass: palette.greens.pass,

    mainText: palette.black,
    secondaryText: palette.neutrals.dark,
    altText: palette.neutrals.dark,
    largerHeaderText: palette.purples.normal,
    placeHolderText: palette.neutrals.dark,
    fadedMainText: palette.neutrals.faded_grey,

    borderColour: palette.neutrals.normal,
    primaryButton: palette.purples.normal,
    secondaryButton: palette.neutrals.normal,
    info: palette.blues.info_light,
    highlight: palette.neutrals.normal,

    navBackground: palette.white,
    nodeCardBackground: palette.white,
    runPropertyBackground: palette.white,
    header: palette.white,
    scrollbar: palette.white,

    dropdownBG: palette.white,
    dropdownHighlight: palette.neutrals.normal,

    freezeButtonBackground: palette.blues.freeze_light,
    freezeButtonText: palette.blues.freeze_dark,
  },

  decoration: {
    cardShadowSM: "rgba(0, 0, 0, 0.2) 0px 1px 4px 0px",
    cardShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.1)",
    cardShadowHover: "0px 1px 8px 0px rgba(0, 0, 0, 0.1)",
    navShadow: "0px 2px 7px 0px rgba(182, 182, 182, 0.5)",
    collapseBtnShadow: "10px 1px 10px 1px rgba(0, 0, 0, 0.1)",
    underShadow: "0 4px 2px -2px rgba(0, 0, 0, 0.1)",
    underlined: "underline",
    graphGridOpacity: "50%",
    graphTextOpacity: "40%",
  },
};

export const dark = {
  ...theme,
  colours: {
    ...theme.colours,
    background: palette.blues.evolve,
    foreground: palette.blues.muted,
    altForeground: palette.blues.smoke,
    haloAccent: palette.purples.normal,
    bulletColour: palette.purples.light,
    bar: palette.blues.evolve,
    pass: palette.greens.success,

    mainText: palette.white,
    secondaryText: palette.white,
    altText: palette.neutrals.dark,
    largerHeaderText: palette.white,
    placeHolderText: palette.neutrals.middleGrey,
    fadedMainText: palette.neutrals.faded_darker_grey,

    borderColour: palette.white,
    primaryButton: palette.purples.normal,
    secondaryButton: palette.blues.smoke,
    info: palette.blues.info_dark,
    highlight: palette.blues.smoke,
    navBackground: palette.blues.muted,
    nodeCardBackground: palette.blues.muted,
    runPropertyBackground: palette.blues.smoke,
    header: palette.blues.muted,
    haloAccentLight: palette.purples.tint,
    scrollbar: palette.blues.smoke,
    dropdownBG: palette.blues.evolve,
    dropdownHighlight: palette.blues.muted,

    freezeButtonBackground: palette.blues.freeze_dark,
    freezeButtonText: palette.blues.freeze_light,

    jira: {
      done: {
        text: "#7DE2B8",
        background: "#1B3329",
      },
      other: {
        text: "#85b8ff",
        background: "#1C2B41",
      },
      to_do: {
        text: "#A0ADBC",
        background: "#2A3035",
      },
    },
  },

  decoration: {
    cardShadow: "none",
    graphGridOpacity: "20%",
    graphTextOpacity: "40%",
    collapseBtnShadow: "none",
  },

  borders: {
    ...theme.borders,
    dropdownBorder: palette.blues.muted,
    headerBorder: palette.blues.smoke,
  },
};
