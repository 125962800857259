import React, { useEffect, useState } from "react";
import {
  StyledCreateFieldContainer,
  StyledContainer,
  StyledMainContainer,
  CreateFormContainer,
  TitleContainer,
  ButtonAndSpinnerContainer,
  StyledErrorMessage,
  StyledField,
  ErrorContainer,
} from "./forms.styles";
import { StyledSelect } from "../../organisation/projectsPicker.styles";
import { productOptions } from "./productOptions";
import { Stripe } from "../../../../../components/stripe/Stripe";
import { useTranslation } from "react-i18next";
import { Button, Message } from "../../../../../components";
import { useUser } from "../../../../../context/user/user.provider";
import { useFormValidation } from "../../../../../context/hooks";
import { useApp } from "../../../../../context/app/app.provider";
import { useModal } from "../../../../../context/modal/modal.provider";

const UnwrappedCreateCustomerForm = ({ setUpdated, updated }) => {
  const { t } = useTranslation();
  const { username } = useUser();

  const [orgId, setOrgId] = useState("");
  const [product, setProduct] = useState("");
  const [orgName, setOrgName] = useState("");
  const { isNotDesktop } = useApp();
  const { openModal, MODALS } = useModal();
  const {
    reason,
    isValid,
    isInvalid,
    validation,
    validateForm,
    resetValidation,
  } = useFormValidation();

  useEffect(() => {
    resetValidation();
  }, []);

  const resetForm = () => {
    setOrgId("");
    setProduct("");
    setOrgName("");
  };

  const openPreviewCustomerModal = () =>
    openModal(MODALS.PREVIEW_CREATE_CUSTOMER, {
      product: product,
      orgId: orgId,
      orgName: orgName,
      username: username,
      setUpdated: setUpdated,
      productLabel: product
        ? productOptions(t).find(option => option.value === product.value)
            ?.label
        : "",
    });

  useEffect(() => {
    if (updated) resetForm();
  }, [updated]);

  useEffect(() => {
    if (isValid) openPreviewCustomerModal();
  }, [isValid, isInvalid, validation, reason]);

  const fieldHandler = (func, val) => {
    resetValidation();
    func(val);
  };

  const buttonHandler = () => {
    validateForm([
      {
        name: "Organisation ID",
        value: orgId,
        validate: ["length", "special", "capitals"],
        params: { min: 3, max: 63 },
      },
      {
        name: "Organisation name",
        value: orgName,
        validate: ["length"],
        params: { min: 2, max: 45 },
      },
    ]);
  };

  return (
    <StyledContainer>
      <TitleContainer>
        <h1>{t("customer.title.createOrganisation")}</h1>
      </TitleContainer>
      <StyledMainContainer>
        <ErrorContainer>
          {isInvalid && validation.length
            ? validation.map(err =>
                err.name && err.text ? (
                  <Message key={err.name} error>
                    {err.text}
                  </Message>
                ) : null
              )
            : null}
        </ErrorContainer>
        <CreateFormContainer
          className={"Form"}
          columns={true}
          data-testid="form-container"
        >
          <StyledCreateFieldContainer
            large
            className={"Field"}
            isNotDesktop={isNotDesktop}
          >
            <StyledField
              name={"org_id"}
              placeholder={t("customer.placeholder.orgId")}
              type={"text"}
              aria-labelledby={"orgId"}
              data-testid={"org_id"}
              value={orgId}
              onChange={e =>
                fieldHandler(setOrgId, e.target.value.toLowerCase())
              }
            />
            <StyledErrorMessage></StyledErrorMessage>
          </StyledCreateFieldContainer>
          <StyledCreateFieldContainer
            large
            className={"Field"}
            isNotDesktop={isNotDesktop}
          >
            <StyledField
              name={"name"}
              placeholder={t("customer.placeholder.orgName")}
              type={"text"}
              aria-labelledby={"name"}
              data-testid={"name"}
              value={orgName}
              onChange={e => fieldHandler(setOrgName, e.target.value)}
            />
            <StyledErrorMessage></StyledErrorMessage>
          </StyledCreateFieldContainer>
          <StyledCreateFieldContainer
            large
            className={"Field"}
            isNotDesktop={isNotDesktop}
          >
            <StyledSelect
              name={"product"}
              placeholder={t("customer.placeholder.product")}
              component={StyledSelect}
              data-test={"product"}
              isMulti={false}
              options={productOptions(t)}
              isClearable={false}
              classNamePrefix={"Select"}
              value={product}
              isNotDesktop={isNotDesktop}
              onChange={value =>
                fieldHandler(
                  setProduct,
                  productOptions(t).find(v => v.value === value.value)
                )
              }
            />
            <StyledErrorMessage></StyledErrorMessage>
          </StyledCreateFieldContainer>
          <ButtonAndSpinnerContainer className={"Field"}>
            <Button
              className="submitButton"
              type="submit"
              disabled={!(orgId && orgName && product)}
              data-testid={"btn-addCustomer"}
              accent={true}
              onClick={() => buttonHandler()}
            >
              {t("customer.button.add")}
            </Button>
          </ButtonAndSpinnerContainer>
        </CreateFormContainer>
      </StyledMainContainer>
    </StyledContainer>
  );
};

export const CreateCustomerForm = props => (
  <Stripe>
    <UnwrappedCreateCustomerForm {...props} />
  </Stripe>
);
