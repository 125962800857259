import React, { useRef } from "react";
import { readableColor } from "polished";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useConfig } from "../../context/config/config.provider";
import { useHistoryWithParams } from "../../context/hooks/historyWithParams/useHistoryWithParams";

export const Button = ({
  children,
  loader,
  loading,
  to,
  captcha,
  buttonId,
  onClick,
  buttonRef,
  ...props
}) => {
  const history = useHistoryWithParams();
  const { config } = useConfig();
  const { t } = useTranslation();
  const captchaRef = useRef(null);

  const handleCaptcha = e => {
    e.preventDefault();
    captchaRef.current?.execute();
  };

  if (to)
    return (
      <>
        {captcha && config.hCaptchaSiteKey ? (
          <HCaptcha
            size="invisible"
            ref={captchaRef}
            sitekey={config.hCaptchaSiteKey}
            onVerify={() => history.push(to)}
          />
        ) : null}
        <Btn
          id={buttonId}
          ref={buttonRef}
          onClick={
            captcha && config.hCaptchaSiteKey
              ? handleCaptcha
              : () => history.push(to)
          }
          {...props}
        >
          <Spn {...props}>{children}</Spn>
        </Btn>
      </>
    );

  if (loading && loader)
    return (
      <LoadingButton {...props}>
        <Spn>{t("btnLoading")}</Spn>
      </LoadingButton>
    );
  else {
    return (
      <>
        {captcha && config.hCaptchaSiteKey ? (
          <HCaptcha
            size="invisible"
            ref={captchaRef}
            sitekey={config.hCaptchaSiteKey}
            onVerify={onClick}
          />
        ) : null}
        <Btn
          onClick={captcha && config.hCaptchaSiteKey ? handleCaptcha : onClick}
          ref={buttonRef}
          id={buttonId}
          {...props}
        >
          <Spn id={buttonId ? `${buttonId}-span` : null}>{children}</Spn>
        </Btn>
      </>
    );
  }
};

export const Btn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  width: ${({ theme, large, width, fitContent }) =>
    width
      ? width
      : fitContent
      ? "fit-content"
      : large
      ? theme.sizing.btnWidthLG
      : theme.sizing.btnWidth};
  height: ${({ theme, height }) => (height ? height : theme.sizing.btnHeight)};
  min-height: ${({ theme, height }) =>
    height ? height : theme.sizing.btnHeight};

  border-radius: ${({ theme }) => theme.borders.radius};
  border: ${({ theme }) => theme.borders.noBorder};

  padding: ${({ padding, fitContent, theme }) =>
    fitContent ? theme.spacing.paddingSM : padding || 0};

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme, open, success, primary, secondary, disabled, danger }) => css`
    background-color: ${(danger && theme.colours.error) ||
    (disabled && theme.colours.background) ||
    (primary && theme.colours.primaryButton) ||
    (success && theme.colours.success) ||
    (open && theme.colours.openButtonBackground) ||
    (secondary && theme.colours.secondaryButton) ||
    theme.colours.primaryButton};
    color: ${(primary && readableColor(theme.colours.primaryButton)) ||
    (secondary && readableColor(theme.colours.secondaryButton)) ||
    (success && readableColor(theme.colours.success)) ||
    (open && theme.colours.haloAccent) ||
    readableColor(theme.colours.primaryButton)};
  `};

  ${({ theme, primary, secondary }) => css`
    &:focus {
      cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
      border: 1px solid
        ${(secondary && theme.colours.primaryButton) ||
        (primary && theme.colours.secondaryButton) ||
        theme.colours.primaryButton};
      outline: ${theme.borders.noBorder};
    }
    &:active {
      background-color: ${(secondary && theme.colours.primaryButton) ||
      (primary && theme.colours.secondaryButton) ||
      theme.colours.secondaryButton};
      color: ${(secondary && readableColor(theme.colours.primaryButton)) ||
      (primary && readableColor(theme.colours.secondaryButton)) ||
      readableColor(theme.colours.primaryButton)};
    }
  `}

  ${({ theme, jira }) => css`
    &:hover {
      background-color: ${jira && theme.colours.primaryButton};
      color: ${jira && readableColor(theme.colours.primaryButton)};
    }
  `}
`;

const LoadingButton = styled(Btn)`
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  cursor: ${({ theme }) => theme.cursors.btnLoading};

  @keyframes loading {
    0% {
      transform: translateX(25px);
    }
    100% {
      transform: translateX(-20px);
    }
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: -100%;
    width: 300%;
    height: 100%;
    background: #8f44fd
      repeating-linear-gradient(
        60deg,
        transparent,
        transparent,
        ${({ theme }) => theme.colours.haloAccent} 10px,
        ${({ theme }) => theme.colours.haloAccent} 20px
      );

    animation: loading 1s infinite linear;
  }
`;

const Spn = styled.span`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  height: 100%;
  width: 100%;
`;
