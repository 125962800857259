import { projectReducer } from "./project.reducer";
import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";

const initialState = {
  project: null,
  projects: [],
};

const [useHook, ProjectProvider] = createState(
  "project",
  initialState,
  projectReducer
);

const useProject = () => {
  const { project, projects, dispatch } = useHook();

  const setProject = prj =>
    dispatch({ type: ACTIONS.SET_PROJECT, payload: prj });

  const setProjects = prjs =>
    dispatch({ type: ACTIONS.SET_PROJECTS, payload: prjs });

  const removeProject = prj =>
    dispatch({ type: ACTIONS.REMOVE_PROJECT, payload: prj });

  const editProject = prj =>
    dispatch({ type: ACTIONS.MODIFY_PROJECT, payload: prj });

  const clearProjects = () => dispatch({ type: ACTIONS.CLEAR_PROJECTS });

  const clearProject = () => dispatch({ type: ACTIONS.CLEAR_PROJECT });

  return {
    project,
    projectId: project?.id?.toString(),
    projects,
    setProject,
    setProjects,
    editProject,
    clearProject,
    clearProjects,
    removeProject,
  };
};

export { useProject, ProjectProvider };
