import { useHistory } from "react-router-dom";
import { PROJECT } from "../../../static/constants/constants";
import { useQueryParams } from "../queryParams/useQueryParams";

export const useHistoryWithParams = () => {
  const history = useHistory();
  const { getParam } = useQueryParams();

  const pushWithParams = (to, ...params) =>
    history.push(`${to}?project=${getParam(PROJECT)}`, ...params);

  const pushWithHash = (to, hash) =>
    history.push(`${to}?project=${getParam(PROJECT)}${hash ? `#${hash}` : ""}`);

  return {
    ...history,
    push: pushWithParams,
    pushWithHash,
  };
};
