import { createContext, useReducer, useEffect, useContext } from "react";

export const createState = (name, initialState, reducer) => {
  const Context = createContext(initialState);
  const useHook = () => useContext(Context);

  const Provider = ({ children }) => {
    const storageItem = localStorage.getItem(name);

    const [state, dispatch] = useReducer(
      reducer,
      JSON.parse(storageItem) || initialState
    );

    useEffect(() => {
      localStorage.setItem(name, JSON.stringify(state));
    }, [state]);

    return (
      <Context.Provider value={{ ...state, dispatch }}>
        {children}
      </Context.Provider>
    );
  };

  return [useHook, Provider];
};
