import React from "react";
import { useTranslation } from "react-i18next";
import { useProject } from "../../../context/project/project.provider";
import {
  Container,
  ProjectIdText,
  OrganisationText,
} from "./projectContainer.styles.js";
import { routes } from "../../../router/routes.js";

export const ProjectContainer = () => {
  const { t } = useTranslation();
  const { project } = useProject();

  if (!(project && project?.id))
    window.location.href = `${window.location.protocol}//${window.location.host}${routes.PROJECTS.path}`;

  return (
    <Container>
      <OrganisationText>{project.name}</OrganisationText>
      <ProjectIdText>{`${t("navigation.id")} ${project.id}`}</ProjectIdText>
    </Container>
  );
};
