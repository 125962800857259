// import { useEffect } from "react";
import { useProject } from "../../../context/project/project.provider";
import { routes } from "../../../router/routes";
// import { useQueryParams } from "../../../context/hooks";
import {
  useHistory,
  // useLocation
} from "react-router-dom";
// import { PROJECT, REDIRECT } from "../../../static/constants/constants";
import { useSubscription } from "../../../context/subscription/subscription.provider";
import { useGetProjects } from "../../../context/project/useGetProjects";

export const useProjectView = () => {
  const { getProjects, projectsPending } = useGetProjects();
  const { setProject, project, projects, setProjects } = useProject();

  // const { getParam } = useQueryParams();
  const history = useHistory();
  // const { hash } = useLocation();
  const { subscription } = useSubscription();

  const maxProjects = subscription?.max_projects || 1;

  const projectCardHandler = payload => {
    // setProject(payload.id);
    history.push(`${routes.HOME.path}?project=${payload.id}`);
  };

  // useEffect(() => {
  //   if (projects) {
  //     const redirectParam = getParam(REDIRECT);
  //     const projectParam = getParam(PROJECT);

  //     if (redirectParam && projectParam) {
  //       setProject(projectParam);
  //       history.push(`${redirectParam}?project=${projectParam}${hash}`);
  //     }
  //   }
  // }, [projects]);

  return {
    project,
    projects,
    setProject,
    setProjects,
    getProjects,
    projectCardHandler,
    projectsPending,
    maxProjects,
    subscriptionId: subscription?.subscription_id,
  };
};
