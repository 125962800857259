import React, { useEffect, useState } from "react";
import { StyledEmailSelect } from "./reports.styles";

const components = {
  DropdownIndicator: null,
};

const createOption = label => ({
  label,
  value: label,
});

export const RecipientsEmails = ({ values, setValues }) => {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);

  useEffect(() => {
    if ((!values || !values.length) && value && value.length) {
      setValue([]);
      setInputValue("");
    }
  }, [values]);

  useEffect(() => {
    if (value) setValues(value.map(val => val.value));
  }, [value]);

  const handleKeyDown = event => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case ",":
      case ";":
      case " ":
      case "Tab":
        setValue(prev => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const handleBlur = event => {
    event.preventDefault();
    event.stopPropagation();

    if (inputValue) {
      setValue([...value, { value: inputValue, label: inputValue }]);
      setValues([...values, inputValue]);
    }
  };

  return (
    <StyledEmailSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={newValue => setValue(newValue)}
      onInputChange={newValue => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      placeholder="Type something and press enter..."
      value={value}
      classNamePrefix={"Select"}
    />
  );
};
